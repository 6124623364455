:root {
  --fg: white;
  --bg: #6600ff;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#headlessui-portal-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
}

#headlessui-portal-root > div {
  width: 100%;
}
