body {
  font-family: Overpass, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}

latin-ext @font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/overpass/overpass-regular.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/overpass/overpass-regular.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/overpass/overpass-semibold.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/overpass/overpass-semibold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/overpass/overpass-bold.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/overpass/overpass-bold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Overpass Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/overpass/overpass-mono-regular.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Overpass Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/overpass/overpass-mono-regular.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* bebas-neue-regular - latin */
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/bebas-neue-v2-latin/bebas-neue-v2-latin-regular.svg#BebasNeue")
      format("svg"); /* Legacy iOS */
}
